export function findItemInList(originalList = [], serviceObj = {} ) {
  let listToBeTraversed = JSON.parse(JSON.stringify(originalList)) || [];

  for (let originalItem = 0; originalItem < listToBeTraversed.length; originalItem++) {
    if (listToBeTraversed[originalItem].functionalGroup == serviceObj.functionalGroup) {
      let partsToBeTraversed = listToBeTraversed[originalItem].parts || [];
      for (let i = 0; i < partsToBeTraversed.length; i++) {
        if (partsToBeTraversed[i].partCode == serviceObj.partCode) {
          return partsToBeTraversed[i];
        }
      }
      listToBeTraversed[originalItem].parts = partsToBeTraversed;
    }
  }
  return null;
}

export function getSelectedFunctionalGroup() {
  if (window.gtMotiveNavigationBoard) {
    return gtMotiveNavigationBoard.getFunctionalGroupId()
  }
}