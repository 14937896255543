import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import moment  from "moment";
import PartsSearchApp from '../app/containers/PartsSearchApp';
import configurePanelStore from '../app/store/configureSearchStore';

require('es6-promise').polyfill();
require('isomorphic-fetch');

const store = configurePanelStore();

moment.locale("en");

render(
  <Provider store={store}>
    <PartsSearchApp/>
  </Provider>,
  document.getElementById('root')
);