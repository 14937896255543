export const PARTS_BE_URL                = window.partsPanelConfig.partsAppUrl || "";
export const PARTS_DASHBOARD_URL         = "/";
export const PARTS_ROOT_URL              = "/";

export const PARTS_SEARCH_REDIRECTION_URL   = "/parts-search/parts-selection";

export const PART_IDENTIFY_SEARCH_API       = PARTS_BE_URL + "/api/v1/gt/identify_search_string_type";
export const PART_EQUIPMENTS_API            = PARTS_BE_URL + "/api/v1/gt/get_umc_and_equipments_list";
export const PART_GT_LIST_API               = PARTS_BE_URL + "/api/v1/gt/get_parts_list";
export const PART_GT_LIST_ASYNC_API         = PARTS_BE_URL + "/api/v1/gt/get_parts_list_async";
export const PART_OE_NUMBER_API             = PARTS_BE_URL + "/api/v1/gt/get_oe_number_from_part";
export const PART_GT_OE_ASYNC_API           = PARTS_BE_URL + "/api/v1/gt/get_oe_number_async";
export const PART_LIST_FROM_OE_ASYNC_API    = PARTS_BE_URL + "/api/v1/parts_list/";
export const FIN_NUMBER_FROM_LICENCE_PLATE  = PARTS_BE_URL + "/api/v1/licence_plate/decode"
export const PLACE_PARTS_ORDER              = PARTS_BE_URL + "/api/v1/parts_order/";
export const PART_ORDER_LIST                = PARTS_BE_URL + "/api/v1/parts_order/";
export const SESSIONS_LOGIN_API             = PARTS_BE_URL + "/api/v1/sessions/login";
export const PAYMENT_INTENT_API             = PARTS_BE_URL + "/api/v1/payments/payment_intent";
export const SHIPPING_ADDRESS_API           = PARTS_BE_URL + "/api/v1/address/autocomplete";
export const SHIPPING_LAT_LONG_API          = PARTS_BE_URL + "/api/v1/address/details";

export const PARTS_SEARCH                   = "/parts-search/";

export const TNC_LINK                       = location.origin + "/terms-and-conditions";
export const EXCEPTION_EMAIL                = PARTS_BE_URL + "/api/v1/emails/send_fe_exception_email";
export const CREATE_FAST_DELIVERY_REQUEST   = PARTS_BE_URL + "/api/v1/parts_order/create_fast_delivery_request";
export const SHIPPING_CHARGES               = PARTS_BE_URL + "/api/v1/shipping_charges/get_charge";
export const UPDATE_PROFILE                 = PARTS_BE_URL + "/api/v1/customers/update_profile";
export const SHIPPING_CHARGES_FAILURE       = PARTS_BE_URL + "/api/v1/parts_order/back_order_email";
