import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import * as siteConstants from '../constants/SiteConstants';
import * as DataPusher from '../helpers/DataPusher';

import * as partsSearchActions from '../actions/partsSearch';
import * as partsActions from '../actions/parts'

import PartsSearchBox from '../components/partsSearch/PartsSearchBox';

// These are included in the layout already
// import "../../../assets/stylesheets/style_guide/colors.scss";
// import '../../../assets/stylesheets/style_guide/panelMargins.scss';
// import '../../../assets/stylesheets/style_guide/panelPaddings.scss';
// import '../../../assets/stylesheets/style_guide/panelTypography.scss';
// import '../../../assets/stylesheets/style_guide/utility.scss';

import '../../../assets/stylesheets/sprites/partPanelSprite.scss';
import '../../../assets/stylesheets/sprites/spriteLogo.scss';
import TopCarBrands from '../components/partsSearch/TopCarBrands';


function PartsSearchApp ( props ){
  const { actions, partsSearchData } = props;
  
  useEffect(() => {
    actions.partsSearchActions.tryCreatingSessionLogin();
    DataPusher.initConnection();
  }, []);

  return (
    <div className="parts-search-app-container md-f500 md-lh14 tc">
      <PartsSearchBox 
        actions = { actions }
        partsSearchData = { partsSearchData }
      />
      <div className='active_text'>Active VIN Filtering – Search with Confidence</div>
      <TopCarBrands />
    </div>
  );
};

PartsSearchApp.propTypes = {
  actions : PropTypes.object.isRequired
};


function mapStateToProps(state) {
  return {
    partsSearchData : state
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      partsSearchActions: bindActionCreators(partsSearchActions, dispatch),
      partsActions: bindActionCreators(partsActions, dispatch)
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartsSearchApp);
  