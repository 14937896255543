import Pusher from 'pusher-js';

var pusher = {};

export function initConnection() {
  let pusherConfig = partsPanelConfig && partsPanelConfig.pusherConfig ? partsPanelConfig.pusherConfig : {};
  let loginAppToken   = localStorage.getItem("loginAppToken");
  let basicAuth       = "";

  if( window.partsPanelConfig.headerAuth ){
    basicAuth = window.partsPanelConfig.headerAuth || "";
  }
  
  pusher = new Pusher( pusherConfig.key , {
    cluster: pusherConfig.cluster,
    authEndpoint: partsPanelConfig.partsAppUrl + '/api/v1/pusher_auth',
    auth: {
      headers: {
        'X-CUSTOMER-TOKEN': loginAppToken,
        'Authorization': basicAuth
      }
    }
  });
  console.log("Initiated Pusher", {pusherConfig} );
}

export function subscribeTo( configObj, bindCallback ) {
  let channel = pusher.subscribe( configObj.channel );
  channel.bind(configObj.stream, configObj.successCB);
  channel.bind('pusher:subscription_succeeded', function() {
    bindCallback && typeof bindCallback == "function" && bindCallback();
  });
  console.log("-- Pusher Subscription --", {configObj} );
}

export function unsubscribeFrom(channel) {
  pusher.unsubscribe(channel);
  console.log("-- Pusher Un Subscription --", {channel} );
}
