import React from 'react';
import '../../../../assets/stylesheets/funnel/components/partsSearch/topCarBrands.scss';

function TopCarBrands() {
  const brands = ['Audi', 'Alfa', 'BMW', 'Mini', 'Volvo', 'Fiat', 'Mercedes Benz', 'Renault', 'Volkswagen'];
  const translationsData = window.partsPanelConfig.partsTranslations.hero_image;

  return (
    <div className='car_brand_cont'>
      <div className='m_25 dfx'>
        {brands.map((brand, index) => (
          <React.Fragment key={index}>
            {index > 0 && <div className='brand_line' />}
            <div className='brand_style'>{brand}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default TopCarBrands;
