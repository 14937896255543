import React from 'react';

import "../../../../assets/stylesheets/funnel/components/partsSearch/partsSearchBox.scss";

function SearchErrorMsg(props) {
  const { ddEnable } = props;
  return (
    <>
      {ddEnable ?
        <div className='pdl_46'>
          <div className='small_box'></div>
          <div className='vin_error_style w_180'>
            <div className='error_img'>
              <span><img src='https://d3brnpc5nhvc9v.cloudfront.net/gp24-images/gp24-icons/white_error_icon.png' /></span>
              <span>Please select a brand</span>
            </div>
          </div>
        </div> :
        <div className='pdl_6'>
          <div className='small_box'></div>
          <div className='vin_error_style'>
            <div className='error_img'>
              <span><img src='https://d3brnpc5nhvc9v.cloudfront.net/gp24-images/gp24-icons/white_error_icon.png' /></span>
              <span>Please provide VIN / License Plate / Part Number</span>
            </div>
          </div>
        </div>}
    </>
  )
}

export default SearchErrorMsg;
