import * as actionTypes from "../constants/ActionTypes";
import * as endpoints from "../constants/Endpoints";

export function subscribeToWatcher(id, timerId) {
  return {
    type: actionTypes.SUBSCRIBE_TO_WATCHER,
    data: {
      id,
      timerId,
    },
  };
}

export function clearWatcher(data) {
  return { type: actionTypes.CLEAR_WATCHER, data };
}

export function tryAddingToWatcher(id, timeout, dataToPost) {
  return function (dispatch) {
    if (id && timeout) {
      let newDataToPost = {
        "data": dataToPost,
      };

      let headers = {
        "Content-Type": "application/json",
      };

		
		  if( window.partsPanelConfig.headerAuth ){
        headers.Authorization = window.partsPanelConfig.headerAuth || "";
		  }
      
      let timerId = setTimeout(() => {
        fetch(endpoints.EXCEPTION_EMAIL, {
          method: "post",
          headers: headers,
          body: JSON.stringify(newDataToPost),
        })
          .then((response) => {
            console.log("response ", response);
          })
          .catch((err) => {
            console.log(err);
          });

        dispatch(clearWatcher(id));
      }, timeout);

      dispatch(subscribeToWatcher(id, timerId));
    } else {
      console.error("incorrect data: ", id, timeout);
    }
  };
}
