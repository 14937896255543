import * as actionTypes from '../constants/ActionTypes';
import * as initialStates from '../constants/InitialStates';
import * as siteConstants from '../constants/SiteConstants';
import * as endpoints from '../constants/Endpoints';
import * as PartsHelper from '../helpers/PartsHelper';


function updateSelectedPartsInfo( stateObj ){

  if( stateObj.dataPosted && stateObj.dataPosted.operation_list && stateObj.dataPosted.operation_list.length ){
    let opList = stateObj.dataPosted.operation_list;
    let parsedOpList = [];
    opList.forEach( ( opObj, index )=>{
      if( !opObj.functionalGroupDescription || !opObj.partDescription ){
        let serviceObj = PartsHelper.findItemInList( stateObj.serviceList, opObj ) || opObj;
        parsedOpList.push( serviceObj );
        stateObj.dataPosted.operation_list = parsedOpList;
      }
    });
  }
  return stateObj;
}

export default function partsSearch(state = initialStates.PARTS_SEARCH_INITIAL_STATE, action) {
  let apiResponse,
      responseData,
      isSuccess,
      stateObj;
  switch ( action.type ) {

    case actionTypes.RESET_PART_SEARCH:
      return initialStates.PARTS_SEARCH_INITIAL_STATE;

    case actionTypes.SHOW_PART_SELECTOR:
      return Object.assign({}, state, {
              showPartsSelector: true
            }); 

    case actionTypes.HIDE_PART_SELECTOR:
      return Object.assign({}, state, {
              showPartsSelector: false
            }); 

    case actionTypes.UPDATE_PART_SEARCH_INPUT:
      return Object.assign({}, state, {
        searchedText: action.data || "",
        licencePlateIdentified: false,
        licencePlateError: false
      }); 

    case actionTypes.UPDATE_PART_SEARCH_OBJECT:
      stateObj = Object.assign({}, state, {
        searchObj: action.data || {},
      }); 
      localStorage.setItem( "partsSearchData", JSON.stringify( stateObj ) );
      window.location.href = endpoints.PARTS_SEARCH_REDIRECTION_URL;
      
      return stateObj;

    case actionTypes.LOAD_STATE_FROM_LS:
      stateObj = localStorage.getItem("partsSearchData") || JSON.stringify( state );
      return Object.assign({}, state, JSON.parse(stateObj), {
        localStorageParsingDone: true
      });

    case actionTypes.PART_BRAND_IDENTIFY_SEARCHED:
      responseData = action.data || {};
      return Object.assign({}, state, {
        identifiedVehicleInfo: responseData.vehicle_info && JSON.parse(JSON.stringify(responseData.vehicle_info)) || {},
        gtVehicleInfo: {},
        finValidated: false
      });

    case actionTypes.PART_SEARCH_IN_PROGRESS:
      return Object.assign({}, state, {
        showInputLoader: true,
        licencePlateError: false
      });  

    case actionTypes.FETCH_GT_MOTIVE_EST_ID:
      return Object.assign({}, state, {
        fetchingMechanicEstimateId: action.data,
      });

    case actionTypes.LICENCE_PLATE_CALL_COMPLETED:
      responseData = action.data || {};
      return { 
          ...state, 
          licencePlateIdentified: true,
          licenseDecodedData: responseData,
          showInputLoader: false,
          licencePlateError: false
      };
    case actionTypes.LICENCE_PLATE_ERROR:
      return {
        ...state,
        licencePlateError: true,
        showInputLoader: false
      }
    case actionTypes.PART_VALIDATING_FIN:
      responseData = action.data || {};
      return Object.assign({}, state, {
        verifyingFin: true,
        finValidated: false,
        finError: []
      });

    case actionTypes.PART_UPDATE_VECHILE_INFO_AND_ENGINE_CODES:
      responseData = action.data || {};
      return Object.assign({}, state, {
        gtVehicleInfo: responseData.vehicle_info && JSON.parse(JSON.stringify(responseData.vehicle_info)) || {},
        engineCodes: responseData.engine_codes || [],
        codes: responseData.codes || [],
        gtMotiveEstimateId: (responseData && responseData.mechanic_gt_estimate_id) || '',
        graphicSearchConfig: { 
                                umc: responseData.vehicle_info.umc,
                                engineCodes: responseData.engine_codes || [],
                                codes: responseData.codes || [],
                              }
      });



    case actionTypes.UPDATE_DATA_POSTED:
      stateObj = Object.assign({}, state, {
        ...state,
        dataPosted: action.data
      });
      
      stateObj = updateSelectedPartsInfo( stateObj );
      return stateObj;

    case actionTypes.PART_FIN_VALIDATED:
      apiResponse = action.data;
      isSuccess = apiResponse && apiResponse.status && apiResponse.status.status == "SUCCESS" ? true : false;
      responseData = apiResponse.payload || [];
      if (isSuccess) {
        stateObj = Object.assign({}, state, {
          finError: [],
          verifyingFin: false,
          finValidated: true,
          serviceList: (responseData && responseData.list) || []
        });
        stateObj = updateSelectedPartsInfo( stateObj );
      } else {
        stateObj = Object.assign({}, state, {
          verifyingFin: false,
          finValidated: false,
          serviceList: [],
          serviceListToBeParsed: [],
          finError: [(responseData.error_code || '')],
          engineCodes: []
        });
      }
      localStorage.setItem("partsSearchData", JSON.stringify(stateObj));
      return stateObj;
      
    default:
      return state;
  }
}