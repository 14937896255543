/*-- Navbar Action Types --*/

export const SHOW_PARTS_SELECTION = "SHOW_PARTS_SELECTION";
export const SHOW_DELIVERY_ADDRESS = "SHOW_DELIVERY_ADDRESS";
export const SHOW_PAYMENT = "SHOW_PAYMENT";
export const SHOW_ORDER_COMPLETE = "SHOW_ORDER_COMPLETE";

/*-- SideBar Action Types Ends --*/

/*-- Cart Action Types --*/
export const SHOW_PARTS_CART = "SHOW_PARTS_CART";
export const CLOSE_PARTS_CART = "CLOSE_PARTS_CART";
export const CHANGE_PART_QUANTITY = "CHANGE_PART_QUANTITY";
export const REMOVE_PART = "REMOVE_PART";
export const CLEAR_PARTS_CART = "CLEAR_PARTS_CART";
export const SHOW_PAYMENT_LOADER = "SHOW_PAYMENT_LOADER";
export const ORDER_PLACED = "ORDER_PLACED";
export const REQUEST_PLACED = "REQUEST_PLACED";
export const ADD_TO_CART = "ADD_TO_CART";
export const PARSE_CART_FROM_LS = "PARSE_CART_FROM_LS";
export const PART_ORDER_FAILED = "PART_ORDER_FAILED";
export const TOTAL_ITEMS_IN_CART = "TOTAL_ITEMS_IN_CART";
export const CART_COST = "CART_COST";
export const SHIPPING_CHARGES_FETCHED = "SHIPPING_CHARGES_FETCHED";
export const RESET_SHIPPING_CHARGES = "RESET_SHIPPING_CHARGES";
export const UPDATE_SHIPPING_API_STATUS = "UPDATE_SHIPPING_API_STATUS"
/*-- Delivery Address Types --*/
export const UPDATE_DELIVERY_INFO = "UPDATE_DELIVERY_INFO";
export const UPDATE_VALIDATION_ERROR = "UPDATE_VALIDATION_ERROR";
export const RESET_VALIDATION_ERROR = "RESET_VALIDATION_ERROR";
export const REMOVE_INPUT_ERROR = "REMOVE_INPUT_ERROR";
export const UPDATE_STATE_FROM_LS = "UPDATE_STATE_FROM_LS"
export const UPDATE_AUTO_SUGGESTION_LIST = "UPDATE_AUTO_SUGGESTION_LIST"
export const FETCHING_ADDRESS = "FETCHING_ADDRESS"
export const UPDATE_SHIPPING_LAT_LONG = "UPDATE_SHIPPING_LAT_LONG"

/* --  Parts Search Action Types  -- */

export const LOAD_STATE_FROM_LS = "LOAD_STATE_FROM_LS";
export const UPDATE_PART_SEARCH_OBJECT = "UPDATE_PART_SEARCH_OBJECT";
export const UPDATE_PART_SEARCH_INPUT = "UPDATE_PART_SEARCH_INPUT";
export const RESET_PART_SEARCH = "RESET_PART_SEARCH";
export const SHOW_PART_SELECTOR = "SHOW_PART_SELECTOR";
export const HIDE_PART_SELECTOR = "HIDE_PART_SELECTOR";
export const PART_SEARCH_IN_PROGRESS = "PART_SEARCH_IN_PROGRESS";
export const PART_BRAND_IDENTIFY_SEARCHED = "PART_BRAND_IDENTIFY_SEARCHED";
export const FETCH_GT_MOTIVE_EST_ID = "FETCH_GT_MOTIVE_EST_ID";
export const LICENCE_PLATE_CALL_COMPLETED = "LICENCE_PLATE_CALL_COMPLETED";
export const PART_VALIDATING_FIN = "PART_VALIDATING_FIN";
export const PART_FIN_VALIDATED = "PART_FIN_VALIDATED";
export const PART_UPDATE_VECHILE_INFO_AND_ENGINE_CODES =
  "PART_UPDATE_VECHILE_INFO_AND_ENGINE_CODES";
export const LICENCE_PLATE_ERROR = 'LICENCE_PLATE_ERROR'

/* --  Parts Search Action Types End  -- */

/*-- Parts Action Types --*/
export const SHOW_PARTS_LOADER = "SHOW_PARTS_LOADER";
export const HIDE_PARTS_LOADER = "HIDE_PARTS_LOADER";
export const HIDE_GRAPHIC_SEARCH = "HIDE_GRAPHIC_SEARCH";
export const SHOW_PARTS_HOME_PAGE = "SHOW_PARTS_HOME_PAGE";
export const SHOW_PARTS_RESULT_PAGE = "SHOW_PARTS_RESULT_PAGE";
export const RESET_PART_DD = "RESET_PART_DD";
export const SHOW_BRAND_DD = "SHOW_BRAND_DD";
export const UPDATE_SEARCH_BRAND = "UPDATE_SEARCH_BRAND";

export const ENABLE_PARTS_RESULT_LISTING = "ENABLE_PARTS_RESULT_LISTING";
export const DISABLE_PARTS_RESULT_LISTING = "DISABLE_PARTS_RESULT_LISTING";

export const PART_SET_FIN_AND_SERVICE_TYPE = "PART_SET_FIN_AND_SERVICE_TYPE";
export const PART_SET_PARSED_SERVICE_LIST = "PART_SET_PARSED_SERVICE_LIST";

export const FETCH_PARTS_FROM_OE_NUMBER = "FETCH_PARTS_FROM_OE_NUMBER";
export const FETCHING_PARTS = "FETCHING_PARTS";
export const PART_LIST_FROM_OE_SUBSCRIPTION = "PART_LIST_FROM_OE_SUBSCRIPTION";
export const PART_LIST_FROM_OE_DATA_FETCH = "PART_LIST_FROM_OE_DATA_FETCH";
export const RESET_PARTS_DATA = "RESET_PARTS_DATA";
export const FAST_DELIVERY_REQUEST_MADE = "FAST_DELIVERY_REQUEST_MADE";
export const CUSTOMER_DETAILS_UPDATED = "CUSTOMER_DETAILS_UPDATED";
export const UPDATE_DATA_POSTED = "UPDATE_DATA_POSTED"

/*-- Parts Action Types Ends --*/

/*-- Payment --*/
export const TOGGLE_CHECKBOX = "TOGGLE_CHECKBOX";
export const CHECKBOX_ERROR_PRESENT = "CHECKBOX_ERROR_PRESENT";
export const CHECKBOX_ERROR_NOT_PRESENT = "CHECKBOX_ERROR_NOT_PRESENT";
export const PAYMENT_INTENT_CREATED = "PAYMENT_INTENT_CREATED";
export const PAYMENT_SUCCESSFUL = "PAYMENT_SUCCESSFUL";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
export const PAYMENT_INITIATED = "PAYMENT_INITIATED";
export const ON_CARD_ELEMENT_EDIT = "ON_CARD_ELEMENT_EDIT";

/*-- Watcher --*/
export const SUBSCRIBE_TO_WATCHER = "SUBSCRIBE_TO_WATCHER";
export const CLEAR_WATCHER = "CLEAR_WATCHER";
