import { SELECTED_PARTS_PATH, COUNTRY } from "../constants/SiteConstants";

export const NAVBAR_INITIAL_STATE = {
  activeSection: SELECTED_PARTS_PATH,
};

export const CART_INITIAL_STATE = {
  showPartsCart: false,
  cartItems: [],
  orderPlaced: false,
  orderInProgress: false,
  orderError: false,
  quantityValidationComplete: false,
  quantityValidationError: false,
  partsOrdered: [],
  totalItems: "",
  shippingCost: 0,
  shippingAPISuccess: true,
  shippingCostCalculated: false,
  charge_code: "",
  cartDataLoaded:false,
  requesPlaced: false,
  cartCost: {
    subTotal: "",
    total: "",
    gst: "",
    formattedShippingCost: "",
    shippingCost: "",
    subTotalAmount: "",
    totalAmount: "",
    formattedGst: "",
  },
  partsOrderedDetails: {
    totalItems: "",
    cartCost: {
      subTotal: "",
      total: "",
      gst: "",
      formattedShippingCost: "",
      shippingCost: "",
      subTotalAmount: "",
      totalAmount: "",
      formattedGst: "",
    },
  },
};

export const DELIVERY_ADDRESS_INITIAL_STATE = {
  fname: "",
  lname: "",
  phn: "",
  email: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  country: COUNTRY,
  instructions: "",
  validationError: [],
  autoSuggestionList: [],
  isAddressFetching: false,
  completeShippingAddress: {}
};

export const PARTS_SEARCH_INITIAL_STATE = {
  licencePlateIdentified: false,
  licenseDecodedData: {},
  gtMotiveEstimateId: "",
  identifiedVehicleInfo: {},
  gtVehicleInfo: {},
  showInputLoader: false,
  verifyingFin: false,
  fetchingMechanicEstimateId: false,
  finValidated: false,
  serviceList: [],
  finError: "",
  engineCodes: [],
  searchObj: {
    finNumber: "",
    searchedText: "",
    operationalList: [],
    oeNumber: "",
  },
  graphicSearchConfig: {},
  showPartsSelector: false,
  localStorageParsingDone: false,
  selectedFunctionalGroup: "",
  dataPosted: {},
  licencePlateError: false
};

export const PARTS_INITIAL_STATE = {
  showResultsPage: false,
  apiError: [],
  fetchingParts: false,
  searchOEObject: null,
  partsList: [],
  fastDeliveryCustomerDetails: {}
};

export const PAYMENT = {
  isTncChecked: false,
  istncCheckboxError: false,
  paymentIntent: "",
  paymentStatus: {
    succeeded: false,
    error: null,
    processing: false,
    disabled: true,
    clientSecret: "",
  },
  payload: {}
};

export const WATCHER = {
  subscribers: {},
};
