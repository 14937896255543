import moment from "moment";
import $ from "jquery";
import * as siteConstants from "../constants/SiteConstants";

export function getParameterByName(name, url, skipLowercaseValues) {
  if (!url) url = window.location.href;
  if (!skipLowercaseValues) {
    url = url.toLowerCase(); // This is just to avoid case sensitiveness
  }
  name = name.replace(/[\[\]]/g, "\\$&"); // This is just to avoid case sensitiveness for query parameter name
  if (!skipLowercaseValues) {
    name = name.toLowerCase();
  }
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getQueryStringParams(
  queryString = location.search.substring(1)
) {
  var params = {},
    decodeText = function (s) {
      return decodeURIComponent(s.replace(/\+/g, " "));
    },
    keyValues = [],
    urlSplits = [];
  if (queryString) {
    keyValues = queryString.split("&");
    for (var i in keyValues) {
      var key = keyValues[i].split("=");
      if (key.length > 1) {
        params[decodeText(key[0])] = decodeText(key[1]);
      }
    }
  }
  return params;
}

export function pushUrlToState(url, title) {
  window.history && window.history.pushState({}, title || "", url);
}

export function replaceUrlInState(url, title) {
  window.history && window.history.replaceState({}, title || "", url);
}

/*
https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
*/
export const CookiesHelper = {
  getItem: function (sKey) {
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            "(?:(?:^|.*;)\\s*" +
              encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") +
              "\\s*\\=\\s*([^;]*).*$)|^.*$"
          ),
          "$1"
        )
      ) || null
    );
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }
    var sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires =
            vEnd === Infinity
              ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT"
              : "; max-age=" + vEnd;
          break;
        case String:
          sExpires = "; expires=" + vEnd;
          break;
        case Date:
          sExpires = "; expires=" + vEnd.toUTCString();
          break;
      }
    }
    document.cookie =
      encodeURIComponent(sKey) +
      "=" +
      encodeURIComponent(sValue) +
      sExpires +
      (sDomain ? "; domain=" + sDomain : "") +
      (sPath ? "; path=" + sPath : "") +
      (bSecure ? "; secure" : "");
    return true;
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) {
      return false;
    }
    document.cookie =
      encodeURIComponent(sKey) +
      "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
      (sDomain ? "; domain=" + sDomain : "") +
      (sPath ? "; path=" + sPath : "");
    return true;
  },
  hasItem: function (sKey) {
    return new RegExp(
      "(?:^|;\\s*)" +
        encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") +
        "\\s*\\="
    ).test(document.cookie);
  },
  keys: /* optional method: you can safely remove it! */ function () {
    var aKeys = document.cookie
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "")
      .split(/\s*(?:\=[^;]*)?;\s*/);
    for (var nIdx = 0; nIdx < aKeys.length; nIdx++) {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
    }
    return aKeys;
  },
};

export const AmountFormatter = {
  toLocaleString: function (
    number,
    skipCurrency,
    skipDecimalFormating,
    locale
  ) {
    let returnValue = "";
    locale = locale || siteConstants.DEFAULT_LOCALE;
    number = parseFloat(number || "");
    if (!number && number !== 0) {
      return returnValue;
    }

    switch (locale) {
      case "en-NZ":
      default:
        if (!skipDecimalFormating) {
          returnValue = number.toFixed(2);
        } else {
          returnValue = number.toString();
        }
        returnValue = returnValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        if (!skipCurrency) {
          returnValue = "$" + returnValue;
        }
    }
    return returnValue;
  },
  toLocaleNumber: function (value, locale) {
    let returnValue = 0;
    locale = locale || siteConstants.DEFAULT_LOCALE;
    if (!value) {
      return returnValue;
    }
    value = value.toString();

    switch (locale) {
      case "de-DE":
      default:
        returnValue = value
          .replace("€", "")
          .replace(/\./g, "")
          .replace(",", ".");

        returnValue = returnValue && parseFloat(returnValue);
    }
    return returnValue || 0;
  },
};

export const DateFormatter = {
  getDateInDisplayFormat: function (
    date,
    displayFormat = siteConstants.DATE_DISPLAY_FORMAT,
    currentFormat = siteConstants.DATE_TIME_FORMAT
  ) {
    let givenDate = moment(date, currentFormat),
      formattedDate,
      hour,
      minutes;
    hour = givenDate && givenDate.get("hour");
    minutes = givenDate && givenDate.get("minutes");
    if (hour > 0 || minutes > 0) {
      formattedDate = givenDate.format(displayFormat) || "";
    } else {
      formattedDate = givenDate.format(siteConstants.DATE_DISPLAY_FORMAT) || "";
    }
    return formattedDate;
  },
};

export function isOnMobile() {
  return window.innerWidth < 768;
}

export function createUUID() {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export function addQueryStringToUrl(page) {
  let oldUrl = location.href.split("?")[0],
    newUrl = "",
    queryString = "";
  if (location.search) {
    let qsArray = location.search.substr(1).split("&"),
      vwIndex = qsArray.findIndex((qs) => qs.includes("vw="));
    if (vwIndex !== -1) {
      qsArray.splice(vwIndex, 1);
    }
    qsArray.push(`vw=${page}`);
    queryString = "?" + qsArray.join("&");
  } else {
    queryString = `?vw=${page}`;
  }
  newUrl = oldUrl + queryString;
  history.pushState({ path: newUrl }, "", newUrl);
}

function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (emailPattern.test(email)) {
    return true;
  }

  return false;
}

function validatePhnNumber(phn) {
  const letterNumber = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
  if (letterNumber.test(phn) && phn.length >= 6) {
    return true;
  }

  return false;
}


export function validateFastTrackDeliveryAddressForm(deliveryAddressData) {
  const {
    fname,
    lname,
    email
  } = deliveryAddressData;

  const errors = [];

  if (!fname.length) {
    errors.push("fname");
  }

  if (!lname.length) {
    errors.push("lname");
  }

  if (!validateEmail(email)) {
    errors.push("email");
  }
  return errors;
}

export function validateDeliveryAddressForm(deliveryAddressData) {
  const {
    fname,
    lname,
    phn,
    email,
    address,
    city,
    zipcode,
    state,
    country,
  } = deliveryAddressData;

  const errors = [];

  if (!fname.length) {
    errors.push("fname");
  }

  if (!lname.length) {
    errors.push("lname");
  }

  if (!validatePhnNumber(phn)) {
    errors.push("phn");
  }

  if (!validateEmail(email)) {
    errors.push("email");
  }

  if (!address.length) {
    errors.push("address");
  }

  // if (!city.length) {
  //   errors.push("city");
  // }


  // if (!zipcode || zipcode.length > 4 ) {
  //   errors.push("zipcode");
  // }

  // if (!state.length) {
  //   errors.push("state");
  // }

  // if (!country.length) {
  //   errors.push("country");
  // }

  return errors;
}

export function scrollToElement(el, scrollBuffer, scrollElement = null) {
  if (el && document.querySelector(el)) {
    let element = document.querySelector(el);
    let elementHeight = (element.offsetTop - element.scrollTop + element.clientTop);
    elementHeight += scrollBuffer;
    if (scrollElement && document.querySelector(scrollElement)) {
      document.querySelector(scrollElement).scrollTo(0, elementHeight)
    } else {
      window.scrollTo(0, elementHeight);
    }
  }
}

export function setItemToSS(key,value) {
  sessionStorage.setItem(key,JSON.stringify(value))
}

export function getItemFromSS(key) {
  let value = JSON.parse(sessionStorage.getItem(key));
  return value;
}

export function removeKeyFromLS(key) {
  localStorage.removeItem(key);
}

export function comparePrice (price, override_price) {
  return (override_price && override_price !== '' && parseFloat(override_price) > parseFloat(price)) ? parseFloat(override_price) : parseFloat(price)
}
